import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"
import Arrow from "../svg/arrow.svg"

const TechnologiesItemsContainerWrapper = styled.div`
  position: relative;
  margin-bottom: 1rem;
  padding-top: 1rem;
  overflow: hidden;
`

const TechnologiesItemsContainer = styled.div`
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  /* width: calc(100vw - 47px); */
  margin: 0 0 4rem;
  padding: 2rem 0;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`

const TechnologiesItemsHeader = styled.div`
  position: relative;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#41aa0a),
    to(#0797b9)
  );
  background-image: linear-gradient(to top, #41aa0a, #0797b9);
  border-radius: 0;
  padding: 2rem;
  margin: 0 2rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
  color: #fff;

  @media (max-width: 768px) {
    margin: 2.5rem;
  }
`

const ArrowRight = styled(Arrow)`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: block;
  }
`

const HeaderUnderTheHood = styled.div`
  margin: 2rem 0;
`
const HeaderUnderTheHoodTitle = styled.h2`
  font-size: 2.6rem;
  margin: 0 0 0.5rem;
`
const HeaderUnderTheHoodPara = styled.p`
  margin: 0;
  font-size: 0.8rem;
`

const TechnologiesItemsWrapper = styled.div`
  scrollbar-color: #201c29;
  scrollbar-width: 10px;
  scrollbar-gutter: always;
  padding: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 768px) {
    display: block;
    margin: 2rem;
  }
`

const TechnologiesLink = styled(Link)`
  text-decoration: none;
`

const TechnologiesItem = styled.div`
  width: 185px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 25px;
  padding: 45px 22px 28px 22px;
  margin: 8px;
  -webkit-box-shadow: 3px 3px 20px 6px rgba(142, 134, 134, 0.16);
  box-shadow: 3px 3px 20px 6px rgba(142, 134, 134, 0.16);
  transition: 0.2s;

  &:hover {
    /* transform: translateY(-15px); */
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 210px;
  }
`

const TechnologiesItemTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-top: 8px;
  font-weight: 700;
  color: #171717;
`

const TechnologiesItemExcerpt = styled.div`
  font-size: 13px;
  color: #757474;
  line-height: 25px;
`

const TechnologiesImage = styled.img`
  max-width: 100%;
  height: 80px;
`

const TechnologiesItems = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpTechnologies {
            edges {
              node {
                id
                title
                slug
                excerpt
                content
                featured_media {
                  source_url
                }
              }
            }
          }
        }
      `}
      render={props => (
        <TechnologiesItemsContainerWrapper>
          <TechnologiesItemsContainer>
            <TechnologiesItemsHeader>
              <HeaderUnderTheHood>
                <HeaderUnderTheHoodTitle>
                  What's <br />
                  Under <br />
                  the <br />
                  Hood
                </HeaderUnderTheHoodTitle>
                <HeaderUnderTheHoodPara>
                  Skills 101
                  <br />
                </HeaderUnderTheHoodPara>
                <ArrowRight />
              </HeaderUnderTheHood>
            </TechnologiesItemsHeader>
            <TechnologiesItemsWrapper>
              {props.allWordpressWpTechnologies.edges.map(technologiesItem => (
                <TechnologiesLink
                  to={`/technologies/${technologiesItem.node.slug}`}
                >
                  <TechnologiesItem key={technologiesItem.id}>
                    <TechnologiesImage
                      src={technologiesItem.node.featured_media.source_url}
                      alt="Image"
                    />
                    <TechnologiesItemTitle>
                      {technologiesItem.node.title}
                    </TechnologiesItemTitle>
                    <TechnologiesItemExcerpt
                      dangerouslySetInnerHTML={{
                        __html: technologiesItem.node.excerpt,
                      }}
                    />
                  </TechnologiesItem>
                </TechnologiesLink>
              ))}
            </TechnologiesItemsWrapper>
          </TechnologiesItemsContainer>
        </TechnologiesItemsContainerWrapper>
      )}
    />
  )
}

export default TechnologiesItems
