import React from "react"
import Layout from "../components/layout"
import TechnologiesItems from "../components/TechnologiesItems"
import styled from "styled-components"
import Dots from "../svg/dots.svg"
import DotsGrey from "../svg/dots-grey.svg"

const TechnologiesLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding: 1rem 0 1rem 3rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const DotsDesign = styled(Dots)`
  height: 150px;
  position: absolute;
  top: 295px;
  left: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
`

const DotsDesignGrey = styled(DotsGrey)`
  height: 200px;
  position: absolute;
  top: 12%;
  left: -20px;
  z-index: -1;
`

const TechnologiesTitle = styled.h1`
  position: relative;
  margin: 42px 0 0 0;
  font-family: "Poppins", sans-serif;
  color: #171717;
  text-align: center;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: -0.4px;
`

const TechnologiesContentText = styled.div`
  text-align: center;
  color: #66686e;
`

const TechnologiesUnderContent = ({ pageContext }) => {
  return (
    <Layout>
      <TechnologiesLayoutWrapper>
        <DotsDesignGrey />
        <TechnologiesTitle
          dangerouslySetInnerHTML={{ __html: pageContext.title }}
        />
        <TechnologiesContentText
          dangerouslySetInnerHTML={{ __html: pageContext.content }}
        />
        <TechnologiesItems />
      </TechnologiesLayoutWrapper>
    </Layout>
  )
}

export default TechnologiesUnderContent
